export default {
  main: {
    knowUs:
      "Let's get to know each other: together we can go farther than you imagine.",
    discover: 'Discover open positions to test your talents.',
    newsletter: "Find out what's behind the magic of Bluemotion.",
    emailField: 'Email',
    newsletterCaption:
      'By clicking subscribe you accept <a href="/" class=“link-underline-1”>terms and conditions</a>',
    corporate:
      '<strong>Bluemotion Srl</strong> Viale del Lavoro 14, int 34, 35020 Ponte San Nicolò, Padova P.iva 03897210286',
    associated: 'We are associated',
    copyright:
      '<span class="show-for-large">Copyright</span>© Bluemotion {year}',
    privacy: 'Privacy Policy',
  },
  medical: {
    title: 'BLUEMOTION MEDICAL',
    textBlock:
      'Complexity meets clarity. Taking medical communication to a new dimension.',
    dedicatedCta: 'VISIT THE DEDICATED SITE',
    url: 'https://bluemotionmedical.com/en',
  },
}
